<template>
  <div class="workSearch">
    <div class="w-input-sty project-select bottom-group">
      <div class="seach-input">
        <el-input
          class="input-solid"
          placeholder="工单编号/工单名称/提交人/工作组"
          v-model="input2"
          @focus="inputFocus"
          @blur="inputBlur"
          clearable
        >
          <i slot="prefix" class="icon-work-scan"></i>
        </el-input>
        <div class="search-draw" v-show="searchDraw">
          <div
            :class="[
              postData.selectStatus === item.key ? 'active-search-type' : '',
              'search-type'
            ]"
            v-for="item in searchTypeList"
            :key="item.key"
            @mousedown="choseSearchType(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="icon-select">
        <el-select
          popper-class="w-block-select-down yellow-select-panel"
          class="input-solid"
          v-model="InitiatorKey"
          placeholder="选择提交人类型"
          @change="queryList"
          clearable
        >
          <div slot="prefix" class="select-icon">
            <i class="icon-work-person"></i>
          </div>
          <el-option
            v-for="item in InitiatorList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="icon-select">
        <el-select
          popper-class="w-block-select-down yellow-select-panel"
          class="input-solid"
          v-model="urgentKey"
          placeholder="选择紧急程度"
          @change="queryList"
          clearable
        >
          <div slot="prefix" class="select-icon">
            <i class="icon-work-level"></i>
          </div>
          <el-option
            v-for="item in urgentList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="date-picker-css">
        <el-date-picker
          popper-class="black-el-date-picker"
          class="input-solid"
          v-model="value2"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="margin-left:10px"
          value-format="yyyy-MM-dd 00:00:00"
          @change="queryList"
        ></el-date-picker>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'workSearch',
  props: ['postData'],
  data () {
    return {
      value2: '',
      searchDraw: false,
      input2: '',
      urgentKey: '',
      InitiatorKey: '',
      urgentList: [
        { value: 1, label: '一般' },
        { value: 2, label: '重要' },
        { value: 3, label: '紧急' }
      ],
      InitiatorList: [
        { value: 1, label: '内部' },
        { value: 2, label: '商户' }
      ],
      searchTypeList: [
        { name: '按工单编号搜索', key: 1 },
        { name: '按工单名称搜索', key: 2 },
        { name: '按提交人搜索', key: 3 },
        { name: '按当前工作组搜索', key: 4 }
      ]
    }
  },
  watch: {
    input2 (val) {
      if (val.length > 0) {
        this.searchDraw = true
        this.postData.keywords = val
      } else {
        this.postData.selectStatus = ''
        this.searchDraw = false
        this.postData.keywords = val
        this.$emit('queryList', this.postData)
      }
    }
  },
  methods: {
    queryList () {
      this.postData.emergencType = this.urgentKey
      this.postData.sponsorType = this.InitiatorKey
      this.postData.sponsorStartTime = this.value2 ? this.value2[0] : ''
      this.postData.sponsorEndTime = this.value2
        ? this.value2[1].replace('00:00:00', '23:59:59')
        : ''
      this.$emit('queryList', this.postData)
    },
    inputFocus () {
      if (this.input2.length > 0) {
        this.searchDraw = true
      }
    },
    inputBlur () {
      this.searchDraw = false
    },
    choseSearchType (e) {
      console.log('e', e)
      if (!e) {
        this.searchDraw = false
        return
      }
      this.postData.selectStatus = e.key
      this.searchDraw = false
      this.postData.pageNum = 1
      this.$emit('queryList', this.postData)
    }
  }
}
</script>
<style lang="stylus" scoped>
.workSearch>>>
  .icon-select, .seach-input
    .el-input__prefix
      left 12px
    .el-input__inner
      padding-left 36px
.active-search-type
  color #F89407
.el-date-editor
  height 36px
  background #373a43 !important
  border none
  line-height unset
.bottom-group
  display flex
  width 100%
.seach-input>>>
  position relative
  width 320px
  .el-input__inner
    width 100%
  .el-input__prefix
    display flex
    align-items center
  .search-draw
    position absolute
    z-index 9
    background #272930
    box-shadow 0px 16px 30px rgba(0, 0, 0, 0.6)
    width 100%
    font-size 14px
    .search-type
      height 44px
      line-height 44px
      padding 0 10px
      cursor pointer
      &:hover
        background #424752
.icon-select>>>
  margin-left 10px
  width 200px
  .el-input__prefix
    display flex
    align-items center
  .el-input__inner
    width 100%
.date-picker-css>>>
  width 440px
  .el-input__inner
    width 100%
</style>
