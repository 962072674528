<template>
  <div class="form-preview-content">
    <FormBuild
      style="height: 100%;"
      :formTemplate="formTemplate"
      :models="models"
      :renderPreview="true"
      ref="formPreview"
      v-if="formVisible"
    />
  </div>
</template>

<script>
import FormBuild from '@/views/workOrderCenter/dynamicForm/packages/form/src/form-build/index.vue'
import api from '@/api'
import Bus from '@/utils/bus.js'
export default {
  data () {
    return {
      formVisible: false,
      formTemplate: {},
      models: {}
    }
  },
  props: {
    drawerId: {
      type: Number
    }
  },
  components: {
    FormBuild
  },
  watch: {
    drawerId (newVal) {
      if (newVal) {
        this.init()
      }
    }
  },
  mounted () {
    this.init()
    Bus.$on('editFormEmitSuccessBus', () => {
      this.init()
    })
  },
  methods: {
    async init () {
      // const value = {
      //   uploadImg_1637907041266: [
      //     {
      //       url: favorImg,
      //       name: '皇冠.png',
      //       uid: 1637914601458,
      //       status: 'success'
      //     },
      //     {
      //       url: favorImg,
      //       name: '皇冠.png',
      //       uid: 1637914601458,
      //       status: 'success'
      //     }
      //   ],
      //   project_1636942188888: '53200',
      //   input_1637907041266: '546164',
      //   date_1637907041266: '2021-11-11',
      //   project_1636942188888_label: '武汉赢商数字购物中心'
      // }
      // const template = require('../../dynamicForm/data/preview.json')
      // const form = await this.getFormTemplate()
      /* eslint-disable */
      const { form, value } = await this.getFormValus()
      this.visible = true
      this.$nextTick(() => {
        if (form) {
          this.formTemplate = eval('(' + form + ')')
          this.templdateText = this.formTemplate
        } else {
          this.formTemplate = {}
          this.templdateText = ''
        }

        if (value) {
          this.models = eval('(' + value + ')')
          this.valueText = this.models
        }

        if (this.models) {
          this.handlerFormRender()
        }
        console.log('formTemplate', this.formTemplate)
        console.log('models', this.models)
      })
      /* eslint-enable */
    },
    getFormTemplate () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getWorkConfigHtml,
          { params: { configId: this.drawerId } })
          .then(res => {
            resolve(res.data.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getFormValus () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getFormContent,
          { params: { type: 4, receiptId: this.drawerId } })
          .then(res => {
            resolve(res.data.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    handlerFormRender () {
      this.formVisible = false
      // this.buildVisible = false
      const model = this.models
      this.$nextTick(() => {
        this.formVisible = true
        // this.buildVisible = true
        if (model && this.$refs.formPreview) {
          this.$nextTick(() => {
            this.$refs.formPreview.setData(model)
            // this.$refs.buildPreview.setData(model)
          })
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped></style>
