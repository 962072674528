<template>
  <div style="width:100%;margin-top:20px">
    <el-table
      class="tbs-black tbs-wrap is-scroll-x tbs-th-light zzzzz"
      :data="pageData.dataList"
      align="center"
    >
      <el-table-column prop="receiptCode" label="工单编号" width="180"></el-table-column>
      <el-table-column prop="receiptName" label="工单名称" width="240px"></el-table-column>
      <el-table-column prop="emergencType" label="紧急程度" width="80px">
        <template slot-scope="scope">
          <UrgentTags :status="scope.row.emergencType" />
        </template>
      </el-table-column>
      <el-table-column prop="receiptStatus" label="工单状态" width="80px">
        <template
          slot-scope="scope"
        >{{ scope.row.receiptStatus === 0 ? '待受理' : scope.row.receiptStatus === 1 ? '受理中' : scope.row.receiptStatus === 2 ? '已完成' : '已关闭' }}</template>
      </el-table-column>
      <el-table-column label="提交人" min-width="180">
        <template slot-scope="scope">
          <div class="user-name">
            {{ scope.row.sponsorUser }}
            <span
              class="user-type"
            >{{ scope.row.sponsorType === 1 ? '内部' : '商户' }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="当前节点" prop="nodeName" width="120px"></el-table-column>
      <el-table-column prop="currentHandler" label="当前处理人" width="120"></el-table-column>
      <el-table-column prop="currentWorkGroup" label="当前工作组" width="120"></el-table-column>
      <el-table-column label="是否超时" width="80px">
        <template
          slot-scope="scope"
        >{{ scope.row.isTimeOut === 0 ? '' : scope.row.isTimeOut === 1 ? '否' : '是' }}</template>
      </el-table-column>
      <el-table-column prop="sponsorTime" label="提交时间" width="200"></el-table-column>
      <el-table-column prop="responseTime" label="受理时间" width="200"></el-table-column>
      <el-table-column prop="lastHandlerTime" label="最近处理时间" width="200"></el-table-column>

      <el-table-column prop="finishTime" label="完成时间" width="200"></el-table-column>
      <el-table-column prop="closeTime" label="关闭时间" width="200"></el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <div class="showBtn" @click="clickDrawer(scope.row)">查看</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="w-page-sty">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageData.total"
        :current-page="pageData.pageNum"
        @current-change="changeCurrent"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import UrgentTags from './urgentTags.vue'
export default {
  name: 'workTables',
  props: {
    pageData: {
      type: Object
    }
  },
  components: { UrgentTags },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    clickDrawer (e) {
      this.$emit('clickDrawer', e)
      this.$emit('getBpmnXML', e)
    },
    changeCurrent (val) {
      this.$emit('getTableList', val)
    }
  }
}
</script>
<style lang="stylus" scoped>
.user-name
  .user-type
    display inline-block
    margin-left 10px
    border 1px solid #FFA134
    width 32px
    height 16px
    line-height 16px
    text-align center
    border-radius 2px
    color #FFA134
.showBtn
  color #F89407
  cursor pointer
  text-align center
.zzzzz>>>
  .el-table__fixed-right
    box-shadow -10px 0px 10px 1px #0000001a
</style>
