<template>
  <div class="urgent-tag">
    <span
      :class="[status==3?'danger':status==2?'important':'commonly']"
      style="margin-left: 10px"
    >{{status==3?'紧急':status==2?'重要':'一般'}}</span>
  </div>
</template>
<script>
export default {
  name: 'urgentTag',
  props: {
    status: {
      type: Number,
      require: true
    }
  },
  data () {
    return {

    }
  }
}
</script>
<style lang="stylus" scoped>
.urgent-tag
  font-size 12px
  color #fff
  display inline-block
  span
    width 32px
    height 16px
    line-height 16px
    text-align center
    display block
    border-radius 2px
  .danger
    background #EF4960
  .important
    background #2C77F9
  .commonly
    background #56B43A
</style>
