<template>
  <div style="height:100%">
    <div class="drawer-header">
      <div class="header-title">
        <div>编辑工单</div>
        <div class="btns">
          <span @click="saveEdit">保存</span>
          <span @click="closeEditDrawer">取消</span>
        </div>
      </div>
    </div>
    <div class="edit-form-wrap">
      <FormBuild
        :formTemplate="formTemplate"
        :models="models"
        ref="formbuild"
        v-if="formVisible"
      />
    </div>
  </div>
</template>

<script>
import FormBuild from '@/views/workOrderCenter/dynamicForm/packages/form/src/form-build/index.vue'
import api from '@/api'
import Bus from '@/utils/bus.js'
export default {
  data () {
    return {
      formVisible: false,
      formTemplate: {},
      models: {}
    }
  },
  components: {
    FormBuild
  },
  watch: {
    drawerId (newVal) {
      console.log(newVal)
    }
  },
  props: {
    drawerId: {
      type: Number
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      /* eslint-disable */
      const { form, value } = await this.getFormValus()
      this.visible = true
      this.$nextTick(() => {
        if (form) {
          this.formTemplate = eval('(' + form + ')')
          this.templdateText = this.formTemplate
        } else {
          this.formTemplate = {}
          this.templdateText = ''
        }
        if (value) {
          this.models = eval('(' + value + ')')
          // console.log(this.models)
          this.valueText = this.models
        }

        if (this.models) {
          this.handlerFormRender()
        }
      })
      /* eslint-enable */
    },
    getFormTemplate () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getWorkConfigHtml,
          { params: { configId: this.drawerId } })
          .then(res => {
            resolve(res.data.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getFormValus () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getFormContent,
          { params: { type: 3, receiptId: this.drawerId } })
          .then(res => {
            resolve(res.data.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    handlerFormRender () {
      this.formVisible = false
      // this.buildVisible = false
      const model = this.models
      this.$nextTick(() => {
        this.formVisible = true
        // this.buildVisible = true
        if (model && this.$refs.formbuild) {
          this.$nextTick(() => {
            this.$refs.formbuild.setData(model)
            // this.$refs.buildPreview.setData(model)
          })
        }
      })
    },
    saveEdit () {
      this.$refs.formbuild.getData().then((data) => {
        const params = {
          receiptId: this.drawerId,
          formValue: JSON.stringify(data)
        }
        this.axios.post(api.changeForm, params).then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '编辑成功！'
            })
            this.$emit('editFormEmitSuccess')
            Bus.$emit('editFormEmitSuccessBus')
          }
        })
      })
    },
    closeEditDrawer () {
      this.$emit('closeEditDrawerEimt')
    }
  }
}
</script>

<style lang="stylus" scoped>
.edit-form-wrap >>>
  .form-design
    height auto
.drawer-header
  height 70px
.header-title
  display flex
  color #FFFFFF
  justify-content space-between
  align-items center
.w-work-center-wrap
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  .w-work-content
    display flex
    color #a5a5a7
    .w-work-left
      background-color #272930
      width 180px
      padding 20px 20px
    .w-work-right
      background-color #272930
      margin-left 20px
      padding 20px
      width calc(100% - 200px)
.btns
  span
    cursor pointer
    &:nth-child(1)
      display inline-block
      width 80px
      height 40px
      line-height 40px
      text-align center
      background #F89407
      opacity 1
      border-radius 2px
    &:nth-child(2)
      display inline-block
      margin-left 10px
      width 80px
      height 38px
      line-height 40px
      text-align center
      border 1px solid #F89407
      opacity 1
      color #F89407
      border-radius 2px
.edit-form-wrap
  padding 40px 64px 40px 55px
  height calc(100% - 70px)
  overflow-y auto
  &::-webkit-scrollbar
    width 6px
    height 16px
    background-color #373a43
  &::-webkit-scrollbar-track
    -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
    border-radius 10px
    background-color #373a43
  &::-webkit-scrollbar-thumb
    border-radius 10px
    height 10px
    -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
    background-color #f89407
    cursor pointer
</style>
