<template>
  <div>
    <el-collapse v-model="activeNames" class="w-el-collapse">
      <el-collapse-item
        :title="item.name"
        :name="item.type"
        v-for="(item,index) in tabList"
        :key="index"
      >
        <div
          :class="[CollpapseIndex === `${item.type}` + jndex ? 'activeCollpse' : '', 'w-el-collapse-item-div']"
          v-for="(jtem,jndex) in item.childVOS"
          :key="jndex"
          @click="choseCollapse(item, jtem, jndex)"
        >
          <div style="padding-left:10px;font-size:14px">{{ jtem.name }}</div>
          <div class="itemNum">{{ jtem.num }}</div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
export default {
  name: 'sideBar',
  props: ['tabList', 'powerList'],
  data () {
    return {
      CollpapseIndex: 0,
      activeNames: ['1']
    }
  },
  methods: {
    choseCollapse (item, jtem, jndex) {
      this.CollpapseIndex = `${item.type}` + jndex
      this.$emit('choseCollapse', item, jtem, jndex)
    }
  },
  mounted () {
    console.log('powerList', this.powerList)
  }
}
</script>
<style scoped lang="stylus">
/deep/.el-collapse-item
  margin-top 10px
/deep/.el-collapse-item__header
  padding 0 10px !important
  height 40px
/deep/.el-collapse-item:hover
  .el-collapse-item__header
    background #33343A !important
    border-radius 24px
/deep/.el-collapse-item__content
  padding-bottom 0 !important
.activeCollpse
  color #ffa134
  font-size 14px
  .itemNum
    font-size 16px
    color #ffa134 !important
.w-el-collapse-item-div
  display flex
  justify-content space-between
  padding 10px 10px
  margin 10px 0
  cursor pointer
  .itemNum
    font-size 14px
    font-weight bold
    color #fff
.w-el-collapse>>>
  background #272930
  border none
  .el-collapse-item__header
    background #272930
    color #a5a5a7
    border none
    font-size 14px
  .el-collapse-item, .el-collapse-item__content, .el-collapse-item__wrap
    background #272930
    color #a5a5a7
    border none
</style>
