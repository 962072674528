<template>
  <div>
    <el-select
      popper-class="w-block-select-down remove-scrollbar"
      class="select-btn"
      v-model="value"
      placeholder="操作"
      @change="changeSelect"
      @click.native="reloadInfo"
    >
      <el-option
        class="btn-option"
        v-for="(item,index) in options"
        :key="index"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>

</template>

<script>
export default {
  name: 'SelectBtn',
  props: ['opts'],
  data () {
    return {
      options: [],
      value: ''
    }
  },
  watch: {
    opts (val) {
      this.options = val.map(item => {
        switch (item) {
          case 2:
            return {
              value: '受理',
              label: '受理'
            }
          case 3: return {
            value: '指派',
            label: '指派'
          }
          case 4: return {
            value: '转交',
            label: '转交'
          }
          case 5: return {
            value: '变更',
            label: '变更'
          }
          case 6: return {
            value: '完成',
            label: '完成'
          }
          case 7: return {
            value: '关闭',
            label: '关闭'
          }
          case 8: return {
            value: '再次提交',
            label: '再次提交'
          }
          case 9: return {
            value: '激活',
            label: '激活'
          }
          case 10: return {
            value: '催单',
            label: '催单'
          }
          case 11: return {
            value: '关注',
            label: '关注'
          }
          case 12: return {
            value: '评论',
            label: '评论'
          }
          case 13: return {
            value: '评价',
            label: '评价'
          }
          case 14: return {
            value: '关联工单',
            label: '关联工单'
          }
          case 15: return {
            value: '取消关注',
            label: '取消关注'
          }
          default:
        }
      })
    }
  },
  methods: {
    reloadInfo (e) {
      // console.log('++=', e)
      // 提交 刷新详情页
      this.$emit('reloadInfo')
    },
    test () {
      console.log('123')
    },
    changeSelect (e) {
      this.$emit('changeOperation', e)
      this.value = '操作'
    }
  }
}
</script>
<style lang="stylus">
.remove-scrollbar
  .el-select-dropdown__wrap
    max-height 10000px
</style>
<style lang="stylus" scoped>
.select-btn>>>
  width 80px
  height 40px
  .el-input
    background #f89407
    input
      &::placeholder
        color #fff !important
      &::-webkit-input-placeholder
        color #fff !important
  .el-input__inner
    background #f89407
    color #fff
    border none
</style>
