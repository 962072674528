import { render, staticRenderFns } from "./urgentTags.vue?vue&type=template&id=1c1d1e16&scoped=true&"
import script from "./urgentTags.vue?vue&type=script&lang=js&"
export * from "./urgentTags.vue?vue&type=script&lang=js&"
import style0 from "./urgentTags.vue?vue&type=style&index=0&id=1c1d1e16&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c1d1e16",
  null
  
)

export default component.exports