<template>
  <div class="w-work-center-wrap">
    <div class="w-work-content">
      <div class="w-work-left">
        <SideBar
          :powerList="[!!powerList[0], !!powerList[1], !!powerList[2]]"
          :tabList="tabList"
          @choseCollapse="choseCollapse"
          v-if="tabList && tabList.length"
        />
      </div>
      <div class="w-work-right" v-loading="pageData === null" element-loading-text="加载中...">
        <WorkSearch @queryList="queryList" :postData="postData" />
        <WorkTable
          :pageData.sync="pageData"
          v-if="hasPower && pageData"
          @clickDrawer="clickDrawer"
          @getBpmnXML="getBpmnXML"
          @getTableList="getTableList"
        />
        <div class="jurisdiction-wrap" v-else>
          <div v-if="pageData !== null">
            <img src="~@/assets/images/Jurisdiction.png" alt />
            <template v-if="pageData">
              <p>暂无权限访问此页，如需开通权限，</p>
              <p>请联系管理员开通</p>
            </template>
            <template v-else>
              <p>暂无数据</p>
            </template>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      :size="isPx"
      class="black-drawer"
      :with-header="false"
      :visible.sync="drawer"
      @hideSidebarEmit="hideSidebarEmit"
      direction="rtl"
      @close="drawerClose"
      ref="drawer"
      wrapperClosable
    >
      <div class="drawer-header">
        <span>工单详情</span>
        <SelectBtns @changeOperation="changeOperation" :opts="opts" @reloadInfo="reloadInfo" />
      </div>
      <div class="drawer-wrap is-scroll-y" v-if="drawer">
        <div class="workDetail">
          <div class="top">
            <div
              class="workNumber"
            >工单编号 {{ orderData.receiptCode }}&nbsp; | &nbsp;{{ orderData.sponsorTime }}</div>
            <div class="workLuncher">
              {{ orderData.receiptName }}
              <UrgentTags :status="parseInt(orderData.emergencType)" />
            </div>
            <div class="workApply">
              {{ orderData.projectName }} -
              {{
                orderData.sponsorType === 1
                  ? orderData.departmentName
                  : orderData.storeName
              }}
            </div>
            <i v-if="orderData.receiptStatus === 0" class="icon-work-pending" style="color:#F89407"></i>
            <i
              v-if="orderData.receiptStatus === 1"
              class="icon-work-processing"
              style="color:#2C77F9"
            ></i>
            <i v-if="orderData.receiptStatus === 2" class="icon-work-solved" style="color:#56B43A"></i>
            <i v-if="orderData.receiptStatus === 3" class="icon-work-closed" style="color:#707070"></i>
          </div>
          <div
            class="workScore"
            v-if="orderData.evaluationVOS && orderData.evaluationVOS.length > 0"
          >
            <div
              v-for="(item, index) in orderData.evaluationVOS"
              :key="index"
            >{{ item.name }}：{{ item.grade }}.0 分</div>
          </div>
          <div class="workScore" v-else>
            <div
              v-for="(item, index) in [
                '响应效率',
                '处理效率',
                '服务态度',
                '服务质量'
              ]"
              :key="index"
            >{{ item }}：-分</div>
          </div>
        </div>

        <div class="w-el-tabs">
          <div>
            <div class="tab-header">
              <div
                :class="[{ 'tab-active': tabActiveIndex === index }, 'tabItem']"
                v-for="(item, index) in tabHeaderText"
                :key="item.id"
                @click="tabClick(index)"
              >{{ item.name }}</div>
            </div>
            <div class="tab-content content" v-show="tabActiveIndex === 0">
              <div class="work-order-content">
                <workContent :drawerId="drawerId" />
              </div>
            </div>
            <div class="tab-content" v-show="tabActiveIndex === 1">
              <!-- 工单流程 -->
              <div v-if="showBpmn" class="bpmn-viewer" ref="bpmnBox"></div>
              <el-button-group key="scale-control" class="scale-control">
                <el-tooltip effect="light" content="缩小视图">
                  <el-button
                    :size="headerButtonSize"
                    :disabled="defaultZoom < 0.2"
                    icon="el-icon-zoom-out"
                    @click="processZoomOut()"
                  />
                </el-tooltip>
                <el-button class="disabled-sty" :size="headerButtonSize">
                  {{
                    Math.floor(this.defaultZoom * 10 * 10) + "%"
                  }}
                </el-button>
                <el-tooltip effect="light" content="放大视图">
                  <el-button
                    :size="headerButtonSize"
                    :disabled="defaultZoom > 4"
                    icon="el-icon-zoom-in"
                    @click="processZoomIn()"
                  />
                </el-tooltip>
                <el-tooltip effect="light" content="重置视图并居中">
                  <el-button
                    :size="headerButtonSize"
                    icon="el-icon-c-scale-to-original"
                    @click="processReZoom()"
                  />
                </el-tooltip>
              </el-button-group>
            </div>
            <div class="tab-content" v-show="tabActiveIndex === 2">
              <div class="process-rcode">
                <el-timeline>
                  <el-timeline-item
                    v-for="(activity, index) in processData"
                    :key="index"
                    color="#F89407"
                  >
                    <div
                      class="rcode-item"
                      :style="activity.opt === '子流程' ? 'cursor:pointer' : ''"
                      @click="viewSunOrder(activity)"
                    >
                      <div class="rcode-i-top">
                        <div>
                          {{
                            activity.opt === "评论"
                              ? "评论"
                              : activity.opt === "评价"
                                ? "评价"
                                : activity.nodeName
                          }}
                        </div>
                        <div>{{ activity.handlerTime }}</div>
                      </div>
                      <div class="rcode-i-bottom" v-html="filterWord(activity.optdesc)"></div>
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </div>
            <div class="tab-content" v-show="tabActiveIndex === 3">
              <div class="relation-form">
                <div class="form-header">
                  <div>关联表单</div>
                  <div>操作</div>
                </div>
                <div class="form-item" v-for="(item, index) in relData" :key="index">
                  <div class="title">{{ item.associatedReceiptName }}</div>

                  <div>
                    <span class="btns" @click="viewForm(item)">查看</span>

                    <span class="btns" @click="disAssociate(item)" style="margin-left:10px">取消关联</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <el-drawer
      :size="isPx"
      class="black-drawer eidit-work-drawer"
      :with-header="false"
      :visible.sync="eiditWorkFlag"
      @hideSidebarEmit="hideSidebarEmit"
      direction="rtl"
      ref="drawer"
      wrapperClosable
    >
      <div v-if="eiditWorkFlag" style="height:100%">
        <editForm
          :drawerId="drawerId"
          @closeEditDrawerEimt="closeEditDrawerEimt"
          @editFormEmitSuccess="editFormEmitSuccess"
        />
      </div>
    </el-drawer>

    <!-- h-操作区域 -->
    <div class="dialogCom">
      <!-- 指派处理人 -->
      <el-dialog
        title="指派工单"
        :visible.sync="assignDialog"
        width="472px"
        :before-close="handleClose"
        custom-class="w-block-dialog"
      >
        <div>
          <el-form
            :model="assignData"
            :rules="assignDataRules"
            ref="assignData"
            label-width="100px"
          >
            <el-form-item label="业务节点" prop="nodeId">
              <el-select
                v-model="assignData.nodeId"
                placeholder="请选择业务节点"
                popper-class="w-block-select-down yellow-select-panel"
                class="input-solid"
                @change="choseNode"
              >
                <el-option
                  v-for="item in businessNode"
                  :key="item.nodeId"
                  :label="item.nodeName"
                  :value="item.nodeId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="指派处理人" prop="userId">
              <el-select
                v-model="assignData.userId"
                placeholder="请选择节点处理人"
                popper-class="w-block-select-down yellow-select-panel"
                class="input-solid"
              >
                <el-option
                  v-for="item in AssignHandlerList"
                  :key="item.userId"
                  :label="item.name"
                  :value="item.userId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="desc">
              <el-input
                class="close-dialog-input"
                type="textarea"
                v-model="assignData.msg"
                maxlength="200"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="footer" slot="footer">
          <div class="sure-btn" @click="assigned">确认</div>
          <div class="close-btn" @click="assignDialog = false">取消</div>
        </div>
      </el-dialog>

      <!-- 完成工单 -->
      <el-dialog
        title="完成工单"
        :visible.sync="finishDialog"
        width="472px"
        :before-close="handleClose"
        custom-class="w-block-dialog"
        @close="$reset('finishData')"
      >
        <div>
          <div>
            <div class="tips">
              <el-form-item label="业务节点">
                <el-select v-model="finishData.nodeId" placeholder="请选择业务节点">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form
                :model="finishData"
                label-width="100px"
                :rules="finishDataRules"
                ref="finishData"
              >
                <el-form-item label="业务节点" prop="nodeId">
                  <el-select
                    v-model="finishData.nodeId"
                    placeholder="请选择业务节点"
                    popper-class="w-block-select-down yellow-select-panel"
                    class="input-solid"
                  >
                    <el-option
                      v-for="item in businessNode"
                      :key="item.nodeId"
                      :label="item.nodeName"
                      :value="item.nodeId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    class="close-dialog-input"
                    type="textarea"
                    v-model="finishData.remark"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div></div>
          </div>
        </div>
        <div class="footer" slot="footer">
          <div class="sure-btn" @click="completeWork">确认</div>
          <div class="close-btn" @click="finishDialog = false">取消</div>
        </div>
      </el-dialog>

      <!-- 正常关闭工单 弹窗-->
      <el-dialog
        title="关闭工单"
        :visible.sync="closeDialog"
        width="472px"
        :before-close="handleClose"
        custom-class="w-block-dialog"
        @close="$reset('closeData1')"
      >
        <div>
          <div class="type-colse">
            <div class="tips">
              <i class="icon-work-tips icon-color"></i>
              <span>关闭后无法再处理该流程，是否确认关闭？</span>
            </div>
            <div>关闭原因:</div>
            <div>
              <el-form
                :model="closeData"
                :rules="closeDataRules"
                ref="closeData1"
                label-width="0"
                class="demo-ruleForm"
              >
                <el-form-item label prop="msg">
                  <el-input
                    class="input-solid close-dialog-input"
                    v-model="closeData.msg"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="footer" slot="footer">
          <div class="sure-btn" @click="comfirmClose">确认</div>
          <div class="close-btn" @click="closeDialog = false">取消</div>
        </div>
      </el-dialog>

      <!-- 异常关闭工单  -->
      <el-dialog
        title="关闭工单"
        :visible.sync="abnormalCloseDialog"
        width="472px"
        :before-close="handleClose"
        custom-class="w-block-dialog"
        @close="$reset('closeData')"
      >
        <div>
          <div class="type-colse" style="width:320px">
            <div class="tips">
              <i class="icon-work-tips icon-color"></i>
              <span>本流程未完成，是否确认提前关闭？关闭后无法再处理该流程。如是，请填写关闭原因（必填）：</span>
            </div>
            <div>
              <el-form
                :model="closeData"
                :rules="closeDataRules"
                ref="closeData"
                label-width="0"
                class="demo-ruleForm"
              >
                <el-form-item label prop="msg">
                  <el-input
                    class="input-solid close-dialog-input"
                    v-model="closeData.msg"
                    ref="abnormalCloseData"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="footer" slot="footer">
          <div class="sure-btn" @click="comfirmClose">确认</div>
          <div class="close-btn" @click="abnormalCloseDialog = false">取消</div>
        </div>
      </el-dialog>

      <!-- 激活 -->
      <el-dialog
        title="激活工单"
        :visible.sync="activeDialog"
        width="472px"
        :before-close="handleClose"
        custom-class="w-block-dialog"
        @close="$reset('activeData')"
      >
        <div>
          <div class="type-colse">
            <div class="tips">
              <i class="icon-work-tips icon-color"></i>
              <span>
                激活后，工单将恢复在关闭前所处的节点，
                是否确认激活？如是，请填写激活原因：
              </span>
            </div>
            <div>
              <el-form :model="activeData" ref="activeData" label-width="0" class="demo-ruleForm">
                <el-form-item label prop="msg">
                  <el-input
                    class="input-solid close-dialog-input"
                    v-model="activeData.msg"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="footer" slot="footer">
          <div class="sure-btn" @click="confirmActive">确认</div>
          <div class="close-btn" @click="activeDialog = false">取消</div>
        </div>
      </el-dialog>

      <!-- 关联工单 -->
      <el-dialog
        title="关联工单"
        :visible.sync="relationDialog"
        width="472px"
        class="dialog-deep-css"
        :before-close="relationDialogClose"
        custom-class="w-block-dialog"
        @close="$reset('AssociatedData')"
      >
        <div v-if="relationDialog">
          <el-form
            :model="AssociatedData"
            ref="AssociatedData"
            :rules="associcatedRule"
            label-width="100px"
          >
            <el-form-item label="关联工单" prop="associatedReceiptId">
              <el-autocomplete
                class="input-solid"
                v-model="AssociatedData.name"
                :fetch-suggestions="getSelectReceiptId"
                placeholder="请输入要关联的工单编号"
                value-key="label"
                @input="changeAssociate"
                @select="handleSelect"
                popper-class="relation-popper"
                style="width:280px"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item prop="name"></el-form-item>
          </el-form>
        </div>
        <div class="footer" slot="footer">
          <div class="sure-btn" @click="associated">确认</div>
          <div class="close-btn" @click="relationDialogClose">取消</div>
        </div>
      </el-dialog>

      <!-- 转交工单 -->
      <el-dialog
        title="转交工单"
        :visible.sync="transferDialog"
        width="500px"
        :before-close="handleClose"
        custom-class="w-block-dialog"
        @close="$reset('transferData')"
      >
        <div>
          <el-form
            :model="transferData"
            :rules="transferRule"
            ref="transferData"
            label-width="110px"
          >
            <el-form-item label="业务节点" prop="nodeId">
              <el-select
                v-model="transferData.nodeId"
                placeholder="请选择业务节点"
                popper-class="w-block-select-down yellow-select-panel"
                class="input-solid"
                @change="changeTransfer"
              >
                <el-option
                  v-for="item in businessNode"
                  :key="item.nodeId"
                  :label="item.nodeName"
                  :value="item.nodeId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-radio-group v-model="transferData.type">
              <el-form-item
                prop="id1"
                :rules="
                  transferData.type === '1'
                    ? transferRule.id1
                    : [{ required: false }]
                "
                :validate-status="
                  transferData.type === '0'
                    ? 'validating'
                    : transferRule.id1
                      ? 'success'
                      : ''
                "
              >
                <span slot="label">
                  <el-radio label="1">{{ "" }}</el-radio>转交到组
                </span>
                <el-select
                  v-model="transferData.id1"
                  placeholder="请选择工作组"
                  :disabled="transferData.type !== '1'"
                  popper-class="w-block-select-down yellow-select-panel"
                  class="input-solid"
                >
                  <el-option
                    v-for="item in teamList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                prop="id2"
                :rules="
                  transferData.type === '0'
                    ? transferRule.id2
                    : [{ required: false }]
                "
                :validate-status="
                  transferData.type === '1'
                    ? 'validating'
                    : transferRule.id2
                      ? 'success'
                      : ''
                "
              >
                <span slot="label">
                  <el-radio label="0">{{ "" }}</el-radio>指 定 人
                </span>
                <el-cascader
                  popper-class="w-block-select-down yellow-select-panel"
                  v-model="transferData.id2"
                  :options="userList"
                  :disabled="transferData.type !== '0'"
                  :props="{ emitPath: false }"
                  @change="handleChange"
                ></el-cascader>
              </el-form-item>
            </el-radio-group>
            <el-form-item label="备注" prop="desc">
              <el-input
                class="input-solid close-dialog-input"
                v-model="transferData.msg"
                maxlength="200"
                show-word-limit
                style="width:280px"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="footer" slot="footer">
          <div class="sure-btn" @click="transferWork">确认</div>
          <div class="close-btn" @click="transferDialog = false">取消</div>
        </div>
      </el-dialog>

      <!-- 受理工单 -->
      <el-dialog
        title="受理节点确认"
        :visible.sync="AcceptanceDialog"
        width="472px"
        :before-close="handleClose"
        custom-class="w-block-dialog"
        v-if="AcceptanceDialog"
      >
        <div v-if="AcceptanceDialog">
          <el-form
            :model="acceptanceData"
            :rules="AcceptanceRules"
            ref="acceptanceData"
            label-width="100px"
          >
            <el-form-item label="业务节点" prop="nodeId">
              <el-select
                v-model="acceptanceData.nodeId"
                placeholder="请选择业务节点"
                popper-class="w-block-select-down yellow-select-panel"
                class="input-solid"
              >
                <el-option
                  v-for="item in businessNode"
                  :key="item.nodeId"
                  :label="item.nodeName"
                  :value="item.nodeId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="footer" slot="footer" v-if="AcceptanceDialog">
          <div class="sure-btn" @click="acceptWork">确认</div>
          <div class="close-btn" @click="AcceptanceDialog = false">取消</div>
        </div>
      </el-dialog>

      <!-- 受理确认 -->
      <el-dialog
        title="受理确认"
        :visible.sync="acpConfirmDialog"
        width="472px"
        :before-close="handleClose"
        custom-class="w-block-dialog"
        v-if="acpConfirmDialog"
      >
        <div>
          <div class="acp-css">
            <div>
              <div>本节点您有需要填写的表单内容，是否前往填写？</div>
              <div style="margin-top:10px">（稍后填写您可点击【变更】按钮进行填写）</div>
            </div>
          </div>
        </div>
        <div class="footer" slot="footer">
          <div class="sure-btn" @click="eiditWorkFn">立即前往</div>
          <div class="close-btn" @click="acpConfirmDialog = false">稍后填写</div>
          <!-- <el-button
            class="close-btn"
            @click="acpConfirmDialog = false"
          >稍后填写</el-button>-->
        </div>
      </el-dialog>

      <!-- 评价 -->
      <el-dialog
        title="评价"
        :visible.sync="evaluateDialog"
        width="472px"
        custom-class="w-block-dialog"
        @close="$reset('evaluateData')"
      >
        <div>
          <el-form :model="evaluateData" ref="evaluateData" label-width="95px">
            <div class="type-comment">
              <div class="tips">
                <div class="comment-thank">
                  <img src="@/assets/images/workCenter/appraise.png" />
                  请您对我们的服务做出评价，这将有助于我们提升服务效率及质量。
                </div>
                <div class="comment-rate">
                  <div v-for="(item, index) in evaluateData.itemList" :key="index">
                    {{ item.item }}
                    <el-rate
                      v-model="item.grade"
                      show-text
                      show-score
                      score-template="{ value }.0分"
                    ></el-rate>
                  </div>
                </div>

                <el-form-item prop="evaluationProposal" label-width="0">
                  <div>您的建议对我们很重要：</div>
                  <el-input
                    class="input-solid close-dialog-input"
                    v-model="evaluateData.evaluationProposal"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="footer" slot="footer">
          <div class="sure-btn" @click="submitEvaluation">确认</div>
          <div class="close-btn" @click="evaluateDialog = false">取消</div>
        </div>
      </el-dialog>

      <!-- 评论 -->
      <el-dialog
        title="评论工单"
        :visible.sync="commentDialog"
        width="472px"
        :before-close="handleClose"
        custom-class="w-block-dialog"
        @close="$reset('commentDate')"
      >
        <div class="tips" v-if="commentDialog">
          <el-form
            :model="commentDate"
            :rules="commentDateRules"
            ref="commentDate"
            label-width="95px"
          >
            <el-form-item label="评论内容" prop="msg" style="color:#fff">
              <el-input type="textarea" v-model="commentDate.msg" maxlength="200" show-word-limit></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="footer" slot="footer">
          <div class="sure-btn" @click="submitComment">确认</div>
          <div class="close-btn" @click="commentDialog = false">取消</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import SelectBtns from './components/selectBtns.vue'
import UrgentTags from './components/urgentTags.vue'
import workContent from './components/workContent.vue'
import editForm from './components/editForm.vue'
import SideBar from './components/workSideBar.vue'
import WorkSearch from './components/worksSearch.vue'
import WorkTable from './components/workCenterTable.vue'
import BpmnModeler from 'bpmn-js/lib/NavigatedViewer'
import { mapGetters } from 'vuex'
export default {
  components: {
    SelectBtns,
    UrgentTags,
    workContent,
    editForm,
    SideBar,
    WorkSearch,
    WorkTable
  },
  props: {
    projectId: {
      type: Number,
      default: 0
    },
    projectName: {
      type: String,
      default: ''
    }
  },
  watch: {
    projectId (newVal, oldVal) {
      this.getList()
      this.getTab()
    }
  },
  data () {
    return {
      hasPower: true,
      powerList: [],
      headerButtonSize: 'mini',
      defaultZoom: 1,
      bpmnData: {},
      showBpmn: false,
      ablePin: [],
      noMatchText: false,
      bpmnViewer: null,
      opts: [],
      searchDraw: false,
      assignDialog: false, // 指派
      finishDialog: false, // 完成工单
      closeDialog: false, // 正常关闭工单
      unnCloseDialog: false, // 异常关闭工单
      activeDialog: false, // 激活工单
      relationDialog: false, // 关联工单
      transferDialog: false, // 转交工单
      abnormalCloseDialog: false, // 异常关闭工单
      AcceptanceDialog: false, // 受理工单
      acpConfirmDialog: false, // 受理确认
      evaluateDialog: false, // 评价工单
      commentDialog: false, // 评论工单
      finishData: {
        nodeId: '',
        receiptId: '',
        remark: ''
      },
      assignData: {
        msg: '',
        nodeId: '',
        receiptId: '',
        userId: ''
      },
      closeData: {
        msg: '',
        receiptId: ''
      },
      activeData: {
        msg: '',
        nodeId: '',
        receiptId: '',
        userId: ''
      },
      transferData: {
        msg: '',
        nodeId: '',
        receiptId: '',
        id1: '',
        id2: '',
        teamOrUserId: '',
        type: '1'
      },
      AssociatedData: {
        // 关联工单数据
        associatedReceiptId: '',
        receiptId: '',
        name: ''
      },
      acceptanceData: {
        nodeId: '',
        receiptId: ''
      },
      acceptData: {
        // 指派数据
        nodeId: '',
        receiptId: ''
      },
      evaluateData: {
        // 评价
        configId: '',
        evaluationProposal: '',
        icon: '',
        itemList: [],
        receiptCode: '',
        receiptId: ''
      },
      commentDate: {
        msg: '',
        receiptCode: '',
        receiptId: ''
      },
      commentDateRules: {
        msg: [{ required: true, message: '请输入评论内容', trigger: 'blur' }]
      },
      assignDataRules: {
        nodeId: [{ required: true, message: '请输入活动名称', trigger: 'blur' }]
      },
      associcatedRule: {
        associatedReceiptId: [
          { required: true, message: '请关联工单', trigger: ['blur', 'change'] }
        ]
      },
      transferRule: {
        nodeId: [
          { required: true, message: '请选择业务节点', trigger: 'blur' }
        ],
        id1: [
          { required: true, message: '请选择组', trigger: ['blur', 'change'] }
        ],
        id2: [
          {
            required: true,
            message: '请选择指定人',
            trigger: ['blur', 'change']
          }
        ]
      },
      AcceptanceRules: {
        nodeId: [{ required: true, message: '请选择业务节点', trigger: 'blur' }]
      },
      finishDataRules: {
        nodeId: [{ required: true, message: '请选择业务节点', trigger: 'blur' }]
      },
      closeDataRules: {
        msg: [{ required: true, message: '请填写关闭原因', trigger: 'blur' }]
      },
      postData: {
        keywords: '',
        selectStatus: 0,
        sponsorType: 0,
        emergencType: 0,
        sponsorStartTime: '',
        sponsorEndTime: '',
        projectId: this.projectId,
        type: 1,
        status: 5,
        pageNum: 1,
        pageSize: 10
      },
      pageData: null,
      dialogVisible: false,
      eiditWorkFlag: false,
      tabActiveIndex: 0,
      tabHeaderText: [
        { name: '工单内容', id: 0 },
        { name: '工单流程', id: 1 },
        { name: '流程记录', id: 2 },
        { name: '关联单据', id: 3 }
      ],
      isPx: '560px',
      drawer: false,
      value: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tabList: [],
      drawerId: '', // 工单详情唯一ID
      orderData: '',
      relData: '',
      processData: '',
      businessNode: [],
      AssignHandlerList: [],
      teamList: [], // 转交组
      userList: [], // 转交指定人
      getSelectData: {
        keywords: '',
        receiptId: ''
      },
      loading: false,
      relOptions: [],
      getHandlerListData: {
        configId: 0,
        nodeId: 0,
        type: 0,
        subConfigId: 0
      }
    }
  },
  methods: {
    processZoomIn (zoomStep = 0.1) {
      const newZoom = Math.floor(this.defaultZoom * 100 + zoomStep * 100) / 100
      if (newZoom > 4) {
        throw new Error(
          '[Process Designer Warn ]: The zoom ratio cannot be greater than 4'
        )
      }
      this.defaultZoom = newZoom
      this.bpmnViewer.get('canvas').zoom(this.defaultZoom)
    },
    processZoomOut (zoomStep = 0.1) {
      const newZoom = Math.floor(this.defaultZoom * 100 - zoomStep * 100) / 100
      if (newZoom < 0.2) {
        throw new Error(
          '[Process Designer Warn ]: The zoom ratio cannot be less than 0.2'
        )
      }
      this.defaultZoom = newZoom
      this.bpmnViewer.get('canvas').zoom(this.defaultZoom)
    },
    processReZoom () {
      this.defaultZoom = this.bpmnViewer
        .get('canvas')
        .zoom('fit-viewport', 'auto')
    },
    drawerClose () {
      this.tabActiveIndex = 0
      this.$router.push({ query: {} })
    },
    reloadInfo () {
      this.getOrderDetail()
    },
    filterWord (text) {
      if (text) {
        return text.replace(
          new RegExp('(点击查看子流程工单;)', 'g'),
          '<font color=#f89407>$1</font>'
        )
      }
    },
    viewSunOrder (e) {
      if (e.opt !== '子流程') {
        return
      }
      const drawHref = this.$router.resolve({
        path: '/workOrderCenter',
        query: {
          receiptId: e.subReciptCode,
          projectId: this.projectId
        }
      })
      window.open('/' + drawHref.href, '_blank')
    },
    disAssociate (item) {
      const postData = {
        associatedReceiptId: item.associatedReceiptId,
        receiptId: this.drawerId
      }
      this.axios.post(api.deleteAssociated, postData).then(res => {
        if (res.data.code === 0) {
          this.$message.success('取消关联成功!')
          this.reAll()
        }
      })
    },
    selectBlur (e) {
      this.$refs.glOrder.value = e.target.value
    },
    changeAssociate (e) {
      this.AssociatedData.name = e.replace(/[^\d.-\w]/g, '')
      this.AssociatedData.associatedReceiptId = ''
    },
    changeTransfer (e) {
      this.getHandlerListData.nodeId = e
      this.getHandlerListData.configId = this.orderData.configId
      this.getHandlerList()
    },
    handleSelect (e) {
      this.AssociatedData.associatedReceiptId = e.value || ''
    },
    relationDialogClose () {
      this.relationDialog = false
      this.AssociatedData.associatedReceiptId = ''
      this.AssociatedData.name = ''
    },
    choseNode (e) {
      console.log(e)
      this.getAssignHandler(e)
    },
    reAll () {
      this.getList()
      this.getTab()
      this.getOrderDetail()
      this.tabActiveIndex = 0
    },
    // 重置dialog
    $reset (formRef) {
      console.log('reset', formRef)
      this.$refs[formRef].resetFields()
    },
    exportWork () {
      if (
        !(this.powerList[this.postData.type - 1] &&
          this.powerList[this.postData.type - 1].func &&
          this.powerList[this.postData.type - 1].func.indexOf('leadingout') !== -1)
      ) {
        this.$message({
          type: 'warning',
          message: `暂无${['所有工单', '组内工单', '我的工单'][this.postData.type - 1]}权限，如需开通权限，请联系管理员开通`
        })
        return false
      }
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      // demo
      this.exportPlan().then(res => {
        // 处理返回的文件流
        const blob = new Blob([res.data])
        const fileName =
          '【' +
          this.projectName +
          '】' +
          '工单中心' +
          '【' +
          year +
          '-' +
          month +
          '-' +
          day +
          '】' +
          '.xlsx'
        // 兼容ie
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
          this.loading = false
        } else {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(() => {
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }, 1000)
        }
      })
    },
    exportPlan () {
      return this.axios({
        // 用axios发送post请求
        method: 'get',
        url: api.exportWork, // 请求地址
        params: this.postData, // 参数
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    viewForm (e) {
      const drawHref = this.$router.resolve({
        path: '/workOrderCenter',
        query: {
          receiptId: e.associatedReceiptId
        }
      })
      window.open('/' + drawHref.href, '_blank')
    },
    getTableList (e) {
      this.postData.pageNum = e
      this.getList()
    },
    transferWork () {
      this.$refs.transferData.validate(valid => {
        if (valid) {
          if (this.transferData.type === '1') {
            this.transferData.teamOrUserId = this.transferData.id1
          } else {
            this.transferData.teamOrUserId = this.transferData.id2
          }
          delete this.transferData.id1
          delete this.transferData.id2
          console.log(this.transferData)
          this.transferData.receiptId = this.drawerId
          this.axios.post(api.transfer, this.transferData).then(res => {
            if (res.data.code === 0) {
              this.$message.success('转交工单成功!')
              this.transferDialog = false
              this.reAll()
            }
          })
        }
      })
    },
    // 评论
    submitComment () {
      this.$refs.commentDate.validate(valid => {
        if (valid) {
          this.commentDate.receiptId = this.drawerId
          this.commentDate.receiptCode = this.orderData.receiptCode
          this.axios.post(api.submitComment, this.commentDate).then(res => {
            if (res.data.code === 0) {
              this.$message.success('评论成功!')
              this.commentDialog = false
              this.reAll()
            }
          })
        } else {
        }
      })
    },
    // 评价
    submitEvaluation () {
      this.evaluateData.receiptId = this.drawerId
      this.evaluateData.receiptCode = this.orderData.receiptCode
      this.evaluateData.configId = this.orderData.configId
      this.axios.post(api.submitEvaluation, this.evaluateData).then(res => {
        if (res.data.code === 0) {
          this.$message.success('评价成功!')
          this.evaluateDialog = false
          this.reAll()
        }
      })
    },
    // 获取工作组
    getHandlerList () {
      this.axios
        .get(api.getHandlerList, { params: this.getHandlerListData })
        .then(res => {
          this.teamList = res.data.data.teamList
          this.userList = res.data.data.userList
            .map(item => {
              const obj = {}
              obj.value = item.id
              obj.label = item.name
              if (item.childList) {
                obj.children = item.childList.map(childItem => {
                  return {
                    value: childItem.id,
                    label: childItem.name
                  }
                })
              }
              return obj
            })
            .filter(x => {
              return x.children !== undefined
            })
        })
    },
    // 获取当前可操作业务节点
    getSelectNode (type) {
      const params = {
        receiptId: this.drawerId,
        type: type
      }
      this.axios.get(api.getSelectNode, { params: params }).then(res => {
        this.businessNode = res.data.data
        if (this.businessNode.length === 1) {
          this.$nextTick(() => {
            // 默认节点选择
            // 受理
            this.acceptanceData.nodeId = this.businessNode[0].nodeId
            if (this.assignDialog) {
              // 指派
              this.assignData.nodeId = this.businessNode[0].nodeId
              this.getAssignHandler(this.assignData.nodeId)
            }

            // 转交
            if (this.transferDialog) {
              this.transferData.nodeId = this.businessNode[0].nodeId
              this.changeTransfer(this.transferData.nodeId)
            }
            // 完成
            this.finishData.nodeId = this.businessNode[0].nodeId
          })
        } else {
          this.acceptanceData.nodeId = ''
        }
      })
    },
    // 获取指派处理人
    getAssignHandler (id) {
      const params = {
        receiptId: this.drawerId,
        nodeId: id
      }
      this.axios.get(api.getAssignHandler, { params: params }).then(res => {
        this.AssignHandlerList = res.data.data
      })
    },
    // 关联工单
    associated () {
      this.$refs.AssociatedData.validate(valid => {
        if (valid) {
          this.AssociatedData.associatedReceiptIdLst = [
            this.AssociatedData.associatedReceiptId
          ]
          this.AssociatedData.receiptId = this.drawerId
          this.axios.post(api.associated, this.AssociatedData).then(res => {
            if (res.data.code === 0) {
              this.$message.success('关联工单成功!')
              this.relationDialog = false
              this.reAll()
            }
          })
        }
      })
    },
    confirmActive () {
      this.activeData.nodeId = this.orderData.nodeId
      this.activeData.receiptId = this.drawerId
      this.axios.post(api.activateWork, this.activeData).then(res => {
        if (res.data.code === 0) {
          this.$message.success('激活成功!')
          this.activeDialog = false
          this.reAll()
        }
      })
    },
    comfirmClose () {
      if (this.abnormalCloseDialog === true) {
        this.$refs.closeData.validate(valid => {
          if (valid) {
            this.closeFn()
          }
        })
      }
      if (this.closeDialog === true) {
        this.closeFn()
      }
    },
    closeFn () {
      this.closeData.receiptId = this.drawerId
      // this.closeData.nodeId = this.orderData.nodeId
      this.axios.post(api.closeWork, this.closeData).then(res => {
        if (res.data.code === 0) {
          this.$message.success('关闭成功!')
          this.closeDialog = false
          this.abnormalCloseDialog = false
          this.reAll()
        }
      })
    },
    // 校验是否正常关闭
    isloseWork () {
      const that = this
      const params = {
        receiptId: ''
      }
      params.receiptId = this.drawerId
      that.axios.get(api.isloseWork, { params: params }).then(res => {
        const flag = res.data.data
        if (flag) {
          // 正常关闭
          this.closeDialog = true
        } else {
          // 异常关闭
          this.abnormalCloseDialog = true
        }
      })
    },
    queryList (e) {
      this.postData = e
      this.postData.pageNum = 1
      this.getList()
    },
    // 完成工单
    completeWork () {
      const that = this
      that.finishData.receiptId = that.drawerId
      that.axios.post(api.completeWork, that.finishData).then(res => {
        if (res.data.code === 0) {
          that.$message.success('完成工单成功!')
          that.finishDialog = false
          this.reAll()
        }
      })
    },
    // 催单
    reminder () {
      const postData = {
        receiptId: this.orderData.receiptId
      }
      this.axios.post(api.reminder, postData).then(res => {
        if (res.data.code === 0) {
          this.$message.success('催单成功')
          this.reAll()
        }
      })
    },
    // 是否关注
    focus (e) {
      const postData = {
        receiptId: this.orderData.receiptId
      }
      this.axios.post(api.focus, postData).then(res => {
        if (res.data.code === 0) {
          if (e === 0) {
            this.$message.success('关注成功！')
            this.reAll()
          } else {
            this.$message.success('取消关注成功！')
            this.reAll()
          }
        }
      })
    },
    handleClose (done) {
      console.log('done-->', done)
      done()
    },
    // 工单指派
    assigned () {
      this.assignData.receiptId = this.drawerId
      this.axios.post(api.assigned, this.assignData).then(res => {
        if (res.data.code === 0) {
          this.$message.success('指派成功!')
          this.assignDialog = false
          this.reAll()
        }
      })
    },
    // 受理工单
    acceptWork () {
      this.$refs.acceptanceData.validate(valid => {
        if (valid) {
          this.acceptanceData.receiptId = this.drawerId
          this.axios.post(api.acceptWork, this.acceptanceData).then(res => {
            if (res.data.code === 0) {
              this.$message.success('受理成功!')
              this.AcceptanceDialog = false
              this.reAll()
              if (res.data.data.canEdit) {
                this.$nextTick(() => {
                  this.acpConfirmDialog = true
                })
              }
            }
          })
        }
      })
    },
    initBpmn () {
      const resData = this.bpmnData
      const that = this
      if (this.bpmnViewer) {
        // 清空
        this.bpmnViewer.clear()

        // 销毁
        this.bpmnViewer.destroy()

        // 脱离dom
        this.bpmnViewer.detach()
      }
      this.bpmnViewer = new BpmnModeler({
        container: this.$refs.bpmnBox
      })
      const getNodeItem = code => {
        const nodeItem = resData.receiptNodeVOS.find(item => {
          return item.nodeCode === code
        })
        return nodeItem
      }
      try {
        const warnings = async function () {
          await that.bpmnViewer.importXML(resData.camundaFile)
          that.defaultZoom = that.bpmnViewer.get('canvas').zoom('fit-viewport', 'auto')
          const ElementRegistry = that.bpmnViewer.get('elementRegistry')
          const overlays = that.bpmnViewer.get('overlays')
          const list = ElementRegistry.getAll().filter(e => {
            return ['bpmn:StartEvent', 'bpmn:SubProcess', 'bpmn:Task'].indexOf(e.type) !== -1
          })
          const getNodeHandler = function (code) {
            const nodeItem = resData.receiptNodeVOS.find(e => {
              return e.nodeCode === code
            })
            if (!nodeItem) {
              return '-'
            }
            let text = ''
            if (nodeItem.handler) {
              if (typeof nodeItem.handler === 'string') {
                text = nodeItem.handler
              } else {
                nodeItem.handler && nodeItem.handler.forEach((e, i) => {
                  text += e.name
                  text += i === nodeItem.length ? ',' : ''
                })
              }
            }
            return text || (nodeItem.type === 'bpmn:StartEvent' ? '所有人' : '-')
          }
          list.forEach(e => {
            const item = e.businessObject
            console.log('item', item)
            overlays.add(e, {
              position: {
                left: item.$type === 'bpmn:StartEvent' ? -92 : -60,
                top: item.$type === 'bpmn:StartEvent' ? -24 : -2
              },
              html: `<div class="bpmn-cover-panel">
                <span class="name">${item.name || '-'}</span>
                <p class="ellipsis2" title="${getNodeHandler(item.id)}">
                  ${getNodeHandler(item.id)}
                </p>
                <ul class="hover-panel">
                  <li>受理时间：${getNodeItem(item.id).acceptsTime ||
                '-'}</li>
                  <li>完成时间：${getNodeItem(item.id).completionTime ||
                '-'}</li>
                </ul>
              </div>
              `
            })
          })
          // that.bpmnViewer.on('element.hover', ({ element }) => {
          //   if (
          //     element.type === 'bpmn:Task' ||
          //     element.type === 'bpmn:SubProcess'
          //   ) {
          //     overlays.add(element, {
          //       position: { right: -10, top: 0 },
          //       html: `<div class="hover-panel">
          //       <ul>
          //         <li>受理时间：${getNodeItem(element.id).acceptsTime ||
          //         '-'}</li>
          //         <li>完成时间：${getNodeItem(element.id).completionTime ||
          //         '-'}</li>
          //       </ul>
          //     </div>`
          //     })
          //   }
          // })
          // that.bpmnViewer.on('element.out', ({ element }) => {
          //   overlays.remove({ element })
          // })
        }
        warnings()
        console.log('rendered')
      } catch (err) {
        console.log('error rendering', err)
      }
    },
    getBpmnXML (e) {
      this.showBpmn = false
      const params = {
        receiptId: this.drawerId
      }
      this.axios
        .get(api.receiptFlow, { params: params })
        .then(
          res => {
            if (res.data.code === 0) {
              this.bpmnData = res.data.data
            }
          },
          rej => {
            console.log(rej)
          }
        )
        .catch(err => {
          console.log(err)
        })
    },
    receiptFlowLog () {
      this.axios
        .get(api.receiptFlowLog, { params: { receiptId: this.drawerId } })
        .then(res => {
          this.processData = res.data.data
        })
    },
    changeCurrent (val) {
      this.postData.pageNum = val
      this.getList()
    },
    getRelOrder () {
      // 根据工单编号获取关联单据
      this.axios
        .get(api.associatedReceiptList, {
          params: { receiptId: this.drawerId }
        })
        .then(res => {
          this.relData = res.data.data
        })
    },
    getOrderDetail () {
      this.axios
        .get(api.getReceiptInfo, { params: { receiptId: this.drawerId } })
        .then(res => {
          if (res.data.code === 0) {
            this.orderData = res.data.data
            this.opts = res.data.data.opts
          }
          this.getBpmnXML()
          this.getRelOrder() // 获取关联单据
          this.receiptFlowLog() // 获取流程记录
        })
    },
    getTab () {
      const params = {
        projectId: ''
      }
      params.projectId = this.projectId
      this.axios.get(api.tablist, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.tabList = res.data.data
        }
      })
    },
    getList () {
      this.pageData = null
      this.postData.projectId = this.projectId
      this.axios
        .get(api.workordercenterList, { params: this.postData })
        .then(res => {
          if (res.data.code === 0) {
            this.pageData = res.data.data
          }
        })
    },

    closeEditDrawerEimt () {
      this.eiditWorkFlag = false
    },
    eiditWorkFn () {
      this.acpConfirmDialog = false
      this.eiditWorkFlag = true
    },
    getSelectReceiptId (queryString, cb) {
      console.log(queryString, cb)
      this.getSelectData.receiptId = this.drawerId
      this.getSelectData.keywords = queryString
      this.getSelectData.selectStatus = 1
      this.getSelectData.pageNum = 1
      this.getSelectData.pageSize = 10000
      this.axios
        .get(api.getSelectReceiptId, { params: this.getSelectData })
        .then(res => {
          console.log('123', res.data)
          if (res.data.code === 0) {
            const arr = res.data.data.dataList
            if (arr.length > 0) {
              this.relOptions = arr.map(item => {
                return { value: item.receiptId, label: `${item.receiptCode}` }
              })
            } else {
              this.relOptions = [
                { value: '', label: '查询不到对应工单编号', disabled: true }
              ]
            }

            cb(this.relOptions)
          }
        })
    },
    // 操作
    changeOperation (e) {
      switch (e) {
        case '受理':
          this.getSelectNode(3)
          this.AcceptanceDialog = true
          return
        case '催单':
          this.reminder()
          return
        case '评价':
          // 获取当前工单可评价项
          this.getEvaluationList()
          // 能否获取到configId
          console.log('this.ooooo', this.orderData.configId)
          this.evaluateDialog = true
          return
        case '关注':
          this.focus(0)
          return
        case '取消关注':
          this.focus(1)
          return
        case '完成':
          this.getSelectNode(4)
          this.finishDialog = true
          return
        case '关闭':
          // 判断是否正常关闭
          this.isloseWork()
          return
        case '激活':
          this.activeDialog = true
          return
        case '关联工单':
          this.relationDialog = true
          return
        case '转交':
          this.getSelectNode(2)
          this.transferDialog = true
          // this.getHandlerList()
          return
        case '指派':
          this.getSelectNode(1)
          this.assignDialog = true
          return
        case '变更':
          this.eiditWorkFn()
          return
        case '再次提交':
          this.$router.push({
            path: '/formSubmit',
            query: {
              id: this.orderData.configId,
              receiptId: this.orderData.receiptId,
              projectId: this.projectId,
              name: this.orderData.receiptName,
              resubmit: 'resubmit'
            }
          })
          return
        case '评论':
          this.commentDialog = true
          return
        default:
          break
      }
      this.dialogVisible = true
    },
    getEvaluationList () {
      // configId: ''
      const obj = {
        configId: this.orderData.configId
      }
      this.axios.get(api.getEvaluationList, { params: obj }).then(res => {
        // this.ablePin = res.data.data
        this.evaluateData.itemList = res.data.data
        console.log('获取评价项成功-->', this.evaluateData.itemList)
      })
    },
    tabClick (e) {
      this.tabActiveIndex = e
      if (e === 1) {
        this.showBpmn = true
        this.$nextTick(() => {
          this.initBpmn()
        })
      }
    },
    clickDrawer (e) {
      this.drawerId = e.receiptId
      this.drawer = true
      this.getOrderDetail() // 获取详情
    },
    hideSidebarEmit () {
      this.drawer = false
    },
    choseCollapse (item, jtem) {
      this.postData.type = item.type
      this.postData.status = jtem.type
      this.postData.pageNum = 1
      this.getList()
    },
    handleChange (val) {
      console.log(val)
    },
    deleteRow (index, rows) {
      rows.splice(index, 1)
    },
    editFormEmitSuccess () {
      this.eiditWorkFlag = false
      this.getOrderDetail()
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    if (this.$route.query.receiptId) {
      // console.log(this.$route.query.projectId, 'this.$route.query.projectId')
      this.drawerId = this.$route.query.receiptId
      this.clickDrawer({ receiptId: parseInt(this.drawerId) })
      // console.log(Boolean(this.$route.query.projectId), 'boolean')
      if (this.$route.query.projectId) {
        this.$emit('onChangeProject', this.$route.query.projectId)
      }
    }
    const userPowerList = this.user.confIds.filter(item => {
      return [237, 238, 239].indexOf(item.id) !== -1
    })
    this.powerList[0] = userPowerList.find(item => {
      return item.id === 237
    })
    this.powerList[1] = userPowerList.find(item => {
      return item.id === 238
    })
    this.powerList[2] = userPowerList.find(item => {
      return item.id === 239
    })
    if (
      this.powerList[0] ||
      this.powerList[1] ||
      this.powerList[2]
    ) {
      this.hasPower = true
    } else {
      this.hasPower = false
    }
    if (this.powerList[0]) {
      this.postData.type = 1
    } else if (this.powerList[1]) {
      this.postData.type = 2
    } else if (this.powerList[2]) {
      this.postData.type = 3
    }
    this.getList()
    this.getTab()
  }
}
</script>
<style lang="stylus">
.relation-popper
  margin-top 1px !important
</style>
<style lang="stylus" scoped>
.acp-css
  display flex
  justify-content center
  font-size 14px
  color #fff
.close-dialog-input>>>
  .el-input__suffix
    right -50px
  .el-input__count-inner
    padding 0
.dialogCom >>>
  .el-form-item__label
    color #fff !important
  .el-input.is-disabled .el-input__icon
    opacity 0.5
  .el-input.is-disabled .el-input__inner
    opacity 0.5
    border-color #373a43
    color #C0C4CC
    cursor not-allowed
  .el-textarea .el-input__count
    background #272930
    color #909399
    position absolute
    font-size 12px
    bottom auto
    right 0
  .el-input .el-input__count .el-input__count-inner
    background #272930
  .el-textarea__inner
    padding-bottom 40px
    &::-webkit-scrollbar
      width 6px
      height 16px
      background-color #373a43
    &::-webkit-scrollbar-track
      -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
      border-radius 10px
      background-color #373a43
    &::-webkit-scrollbar-thumb
      border-radius 10px
      height 10px
      -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
      background-color #f89407
      cursor pointer
.drawer-header
  height 70px
  display flex
  justify-content space-between
  align-items center
  color #fff
/deep/.el-scrollbar
  background #272930 !important
/deep/.el-select-dropdown
  border 1px solid #272930 !important
/deep/.el-select-dropdown__list
  background #272930 !important
.w-work-center-wrap>>>
  .el-timeline-item__tail
    border-left 1px dashed #686E74
  .el-collapse-item__header
    font-size 14px
// 滚动条样式自定义
.process-rcode
  &::-webkit-scrollbar
    width 6px
    height 16px
    background-color #373a43
  &::-webkit-scrollbar-track
    -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
    border-radius 10px
    background-color #373a43
  &::-webkit-scrollbar-thumb
    border-radius 10px
    height 10px
    -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
    background-color #272930
    cursor pointer
.tab-content>>>
  position relative
  .scale-control
    position absolute
    bottom 10px
    right 10px
    .el-button
      background #2e3139
      color #b2b3bd
      border 1px solid #272930
      &:hover
        background #2e3139 !important
.tab-content
  min-height 560px
  background #373A43
  overflow hidden
  .bpmn-viewer
    width 512px
    height 560px
  .contentTitle
    color #B2B3BD
  .contentText
    color #FFFFFF
  .process-rcode
    padding 40px
    overflow-y scroll
    max-height 560px
    .rcode-item
      color #B2B3BD
      .rcode-i-top
        display flex
        justify-content space-between
      .rcode-i-bottom
        color #fff
        margin-top 10px
        line-break anywhere
  .relation-form
    padding 40px
    font-size 14px
    .form-header
      color #B2B3BD
      display flex
      justify-content space-between
    .form-item
      margin-top 20px
      display flex
      justify-content space-between
      .title
        color #FFFFFF
      .btns
        color #F89407
        cursor pointer
.tab-active
  color #fff
  background #373a43 !important
.tab-header
  display flex
  color #B2B3BD
  background-color #272930
  .tabItem
    width 25%
    text-align center
    height 36px
    line-height 36px
    background #2e3139
    cursor pointer
    font-size 14px
    &:nth-child(n+2)
      margin-left 2px
.workDetail
  .top
    position relative
    left 0
    top 0
    i
      position absolute
      right -30px
      top -20px
  .workNumber
    color #B2B3BD
    font-size 14px
  .workLuncher
    color #fff
    margin-top 10px
  .workApply
    color #B2B3BD
    margin-top 30px
    font-size 14px
  .workScore
    color #B2B3BD
    display flex
    margin-top 10px
    font-size 14px
    width 100%
    div
      width 25%
      // &:nth-child(n+2)
      // margin-left:40px
.el-date-editor
  height 36px
  background #373a43 !important
  border none
  line-height unset
/deep/.el-range-separator
  color #909399
/deep/.el-tabs__nav-next, /deep/.el-tabs__nav-prev
  display none
.w-el-tabs>>>
  background #373A43
  margin-top 32px
  .el-tabs__nav-wrap
    padding 0
  .content
    position relative
    .line
      padding 20px 0
      position absolute
      bottom 0
      background #373A43
      width 100%
      box-shadow 0 -4px 30px -20px #000
      .btn
        width 120px
        height 40px
        background #F79306
        opacity 1
        color #fff
        text-align center
        line-height 40px
        border-radius 2px
        margin 0 auto
        cursor pointer
.header-title
  display flex
  color #FFFFFF
  justify-content space-between
  align-items center
.w-work-center-wrap
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  .w-work-content
    display flex
    color #a5a5a7
    .w-work-left
      background-color #272930
      width 184px
      padding 20px 10px
    .w-work-right
      background-color #272930
      margin-left 20px
      padding 20px
      width calc(100% - 204px)
.eidit-work-drawer
  .btns
    span
      cursor pointer
      &:nth-child(1)
        display inline-block
        width 80px
        height 40px
        line-height 40px
        text-align center
        background #F89407
        opacity 1
        border-radius 2px
      &:nth-child(2)
        display inline-block
        margin-left 10px
        width 80px
        height 38px
        line-height 40px
        text-align center
        border 1px solid #F89407
        opacity 1
        color #F89407
        border-radius 2px
.drawer-wrap
  overflow hidden
  height calc(100% - 70px)
.dialogCom>>>
  .el-rate__text
    color #F89407 !important
    margin-left 20px
  .el-input
    background none
  .el-input__inner
    background #373A43
    color #fff
  .el-textarea__inner
    background #373A43
    border none
    color #fff
  .el-dialog__body
    padding 40px !important
  .icon-color
    color #ff754c
  .type-colse
    width 290px
    margin 0 auto
    font-size 14px
    color #fff
    div
      &:nth-child(n+2)
        margin-top 16px
  .type-comment
    width 290px
    margin 0 auto
    font-size 14px
    color #fff
    .comment-thank
      display flex
      color #B2B3BD
      img
        width 40px
        height 40px
        margin-right 10px
    .comment-rate
      margin-top 40px
      div
        display flex
        .el-rate
          margin-left 20px
    div
      &:nth-child(n+2)
        margin-top 16px
  .footer
    display flex
    justify-content center
    .sure-btn
      background #F89407
      font-size 14px
      width 88px
      height 36px
      line-height 36px
      text-align center
      cursor pointer
      color #fff
      border-radius 4px
    .close-btn
      background #373A43
      width 88px
      height 36px
      line-height 36px
      text-align center
      color #fff
      cursor pointer
      font-size 14px
      margin-left 20px
      border-radius 4px
</style>
